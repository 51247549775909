import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import { FAQJsonLd } from 'gatsby-plugin-next-seo'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql, StaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import casinolistStyle from "../components/Casinolist.module.css"

class Nettikasinot extends React.Component {
       //LOAD MORE
       constructor(props) {
        super(props);
        this.state = {
          items: [],
          visible: 20,
          error: false,
        };
        this.loadMore = this.loadMore.bind(this);
        this.showLess = this.showLess.bind(this);
      }
      loadMore() {
        this.setState((prev) => {
          return { visible: prev.visible + 20 };
        });
      }
      showLess() {
        this.setState((prev) => {
          return { visible: 20 };
        });
      }
  componentDidMount() {
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname != window.location.hostname) {
            links[i].target = '_blank';
            links[i].rel = 'noopener';
        }
    }
    var lisa = document.getElementsByClassName("tab-lisa");
		var bonus = document.getElementsByClassName("tab-bonus");
		var maksu = document.getElementsByClassName("tab-maksu");
		var i;
		for (i = 0; i < lisa.length; i++) {
			lisa[i].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[0].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[0].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
					this.parentNode.nextElementSibling.childNodes[2].style.display = "none";
					this.nextElementSibling.classList.remove("active");
					this.nextElementSibling.nextElementSibling.classList.remove("active");
				}
			});
		}
		var j;
		for (j = 0; j < bonus.length; j++) {
			bonus[j].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[1].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[1].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
					this.parentNode.nextElementSibling.childNodes[2].style.display = "none";
					this.nextElementSibling.classList.remove("active");
					this.previousElementSibling.classList.remove("active");
				}
			});
		}					
		var k;
		for (k = 0; k < maksu.length; k++) {
			maksu[k].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[2].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[2].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[2].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
					this.previousElementSibling.classList.remove("active");
					this.previousElementSibling.previousElementSibling.classList.remove("active");
				}
			});
		}

		var talletus = document.getElementsByClassName("talletus");
		var o;
		for (o = 0; o < talletus.length; o++) {
			var trustly = "<img src='/images/maksu/trustly.webp' alt='trustly' class='lazyload'/>"
			var euteller = "<img src='/images/maksu/euteller.webp'  alt='euteller' class='lazyload'/>"
			var visa = "<img src='/images/maksu/visa.webp' alt='visa' class='lazyload'/>"
			var visaelectron = "<img src='/images/maksu/visa-electron.webp' alt='visaelectron' class='lazyload'/>"
			var skrill = "<img src='/images/maksu/skrill.webp' alt='skrill' class='lazyload'/>"
			var mastercard = "<img src='/images/maksu/mastercard.webp' alt='mastercard' class='lazyload'/>"
			var maestro = "<img src='/images/maksu/maestro.webp' alt='maestro' class='lazyload'/>"
			var neteller = "<img src='/images/maksu/neteller.webp' alt='neteller' class='lazyload'/>"
			var paysafecard = "<img src='/images/maksu/paysafecard.webp' alt='paysafecard' class='lazyload'/>"
			var zimpler = "<img src='/images/maksu/zimpler.webp' alt='zimpler' class='lazyload'/>"
      var brite = "<img src='/images/maksu/brite.webp' alt='brite' class='lazyload'/>"
			var str = talletus[o].innerHTML;

			var res = str.replace("Trustly", trustly).replace("Euteller", euteller).replace("Brite", brite).replace("Visa", visa).replace("Visa Electron", visaelectron).replace("Skrill", skrill).replace("Mastercard", mastercard).replace("Maestro", maestro).replace("Neteller", neteller).replace("Paysafecard", paysafecard).replace("Zimpler", zimpler);
			talletus[o].innerHTML = res;
		}
  }
  render() {
  return (
    <StaticQuery
      query = {graphql`
      query {
        contentfulMoneysivujenOsiot(slug: {eq: "nettikasinot"}) {
          slug
          sivu
          ylosanTeksti {
            json
          }
          kasinolista {
            kasinonNimi
            kasinonListanimi
            bonus
            ilmaiskierrokset
            rahansiirto
            logonTiedostonimi
            listalogo {
              title
              file {
                url
              }
            }
            afflink
            kasinonLisatiedot {
              json
              }
            bonustiedot {
              json
            }
          }
          alaosanTeksti {
            json
          }
          alaosanTeksti2 {
            json
          }
        alaosanTeksti3 {
          json
        }
        }
      }
      `}
      render={data => (
    <Layout>
      <GatsbySeo
      title='Nettikasinot | Ultimaattinen katalogi! | Ei jätettä!'
      description='Ainoastaan kaikki nettikasinot jotka on laatutarkastettu ylläpidon toimesta. Valitse luotettava nettikasino ja unohda kompostiin joutavat jäteläjät!'
   
   />
        <FAQJsonLd
            questions={[
              { question: 'Nettikasino jäädytti pelitilini? Miten toimin?', answer: 'Laillisen pelilisenssin alaisuudessa toimivat nettikasinot eivät jäädytä asiakkaan pelitiliä ilman syytä. Aivan ensimmäiseksi selvitä kasinon asiakaspalvelun yhteystiedot ja tiedustele ongelmaa sieltä.' },
              { question: 'Sain pyynnön lähettää kuvan passista nettikasinolle. Onko tämä turvallista?', answer: 'Monet nettikasinot varmistavat asiakkaan henkilöllisyyden pyytämällä kuvaa tai skannausta passista ja esim. sähkölaskusta. Tämä on täysin normaali ja turvallinen käytäntö.' },
              { question: 'Kuinka nopeasti nettikasinot maksavat voitot?', answer: 'Rahansiirtojen nopeus on täysin kasinokohtaista. Laadukkaat nettikasinot saattavat maksaa voittosi jopa viidessä minuutissa, kun taas epämääräisemmät toimijat panttaavat voittojasi useita päiviä.' },
              { question: 'Onko ulkomaisilla nettikasinoilla suomenkielinen asiakaspalvelu?', answer: 'Nykyisin todella usea kasino tarjoaa suomenkielisen asiakaspalvelun. Kasinosivu.com suosii rankingissaan juurikin näitä kasinoita. Lue sivuiltamme lisätietoja suomi-nettikasinoista.' },
              { question: 'Mikä on nettikasinoiden palautusprosentti?', answer: 'Nettikasinoiden palautusprosentit vaihtelevat kasino- ja jopa pelikohtaisesti. Ulkomaisten kasinoiden palautusprosentit ovat poikkeuksetta parempia kuin kotimaisen Veikkauksen nettikasinon palautusprosentti. Muista kuitenkin, että palautusprosentti on aina alle 100%. Etu on kasinolla.' },
              { question: 'Haluaisin jäädyttää/sulkea nettikasinon pelitilin, kuinka toimin?', answer: 'Turvallisiksi testatuilla kansainvälisillä nettikasinoilla pelitilin jäädyttäminen tai sulkeminen on tehty erittäin helpoksi. Etsi valikoista Oma tili --> vastuullinen pelaaminen ja näin voit sulkea tai jäädyttää asiakastilisi joko kokonaan tai määräajaksi parilla klikkauksella.' },
            ]}
          />
        <Container>
        <PageTitle>{data.contentfulMoneysivujenOsiot.sivu}</PageTitle>
        <div className="ylaosa">{documentToReactComponents(data.contentfulMoneysivujenOsiot.ylosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
        })}</div>
        <table>
          <tbody>
            {data.contentfulMoneysivujenOsiot.kasinolista .slice(0, this.state.visible) .map(({listalogo,logonTiedostonimi,kasinonListanimi,bonus,ilmaiskierrokset,afflink,kasinonLisatiedot,bonustiedot,rahansiirto}) => (
            <tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={`/images/logo/${logonTiedostonimi}`} alt={listalogo.title} className="lazyload" width="120" height="50"/>
									<div className={casinolistStyle.name}>
										<span className={casinolistStyle.title}><b>{kasinonListanimi}</b></span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset</i>
												<p><b>{bonus}</b></p>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset</i>
												<p><b>{ilmaiskierrokset}</b></p>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={afflink} target="_blank">Pelaa heti!</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.expand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-maksu`}><span>Maksutavat</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(kasinonLisatiedot.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(bonustiedot.json)}</div>
									<div className={`${[casinolistStyle.expandInfo, casinolistStyle.expandMaksu].join(' ')} maksu`} >
										<span>Talletus- ja kotiutustavat: </span><div className="talletus"> {rahansiirto}</div>
									</div>
								</div>
							</td>
						</tr>
            ))}
          </tbody>
        </table>
        <div className="loadBtn">
                    {this.state.visible > 20 && (
                      <button
                        onClick={this.showLess}
                        type="button"
                        id="showLess"
                      >
                        Vähemmän
                      </button>
                    )}
                    {this.state.visible < data.contentfulMoneysivujenOsiot.kasinolista.length && (
                      <button
                        onClick={this.loadMore}
                        type="button"
                        id="loadMore"
                      >
                        Näytä lisää
                      </button>
                    )}
                  </div>
        <div>
                <h3>Näitä kysytään meiltä usein:</h3>
                <p>Nettikasinolla pelatessa saattaa syntyä ongelmatilanteita. Alla näkyviä kysymyksiä meiltä tiedustellaan usein.</p>
                <div>
                  <div><h4>Nettikasino jäädytti pelitilini? Miten toimin? </h4></div>
                  <p>Laillisen pelilisenssin alaisuudessa toimivat nettikasinot eivät jäädytä asiakkaan pelitiliä ilman syytä. Aivan ensimmäiseksi selvitä kasinon asiakaspalvelun yhteystiedot ja tiedustele ongelmaa sieltä.</p>
                </div>
                <div>
                  <div><h4>Sain pyynnön lähettää kuvan passista nettikasinolle. Onko tämä turvallista? </h4></div>
                  <p>Monet nettikasinot varmistavat asiakkaan henkilöllisyyden pyytämällä kuvaa tai skannausta passista ja esim. sähkölaskusta. Tämä on täysin normaali ja turvallinen käytäntö.</p>
                </div>                
                <div>
                  <div><h4>Kuinka nopeasti nettikasinot maksavat voitot?</h4></div>
                  <p>Rahansiirtojen nopeus on täysin kasinokohtaista. Laadukkaat nettikasinot saattavat maksaa voittosi jopa viidessä minuutissa, kun taas epämääräisemmät toimijat panttaavat voittojasi useita päiviä.</p>
                </div>     
                <div>
                  <div><h4>Onko ulkomaisilla nettikasinoilla suomenkielinen asiakaspalvelu? </h4></div>
                  <p>Nykyisin todella usea kasino tarjoaa suomenkielisen asiakaspalvelun. Kasinosivu.com suosii rankingissaan juurikin näitä kasinoita. Lue sivuiltamme lisätietoja suomi-nettikasinoista.</p>
                </div> 
                <div>
                  <div><h4>Mikä on nettikasinoiden palautusprosentti? </h4></div>
                  <p>Nettikasinoiden palautusprosentit vaihtelevat kasino- ja jopa pelikohtaisesti. Ulkomaisten kasinoiden palautusprosentit ovat poikkeuksetta parempia kuin kotimaisen Veikkauksen nettikasinon palautusprosentti. Muista kuitenkin, että palautusprosentti on aina alle 100%. Etu on kasinolla.</p>
                </div> 
                <div>
                  <div><h4>Haluaisin jäädyttää/sulkea nettikasinon pelitilin, kuinka toimin? </h4></div>
                  <p>Turvallisiksi testatuilla kansainvälisillä nettikasinoilla pelitilin jäädyttäminen tai sulkeminen on tehty erittäin helpoksi. Etsi valikoista Oma tili -- vastuullinen pelaaminen ja näin voit sulkea tai jäädyttää asiakastilisi joko kokonaan tai määräajaksi parilla klikkauksella.</p>
                </div>            
              </div>
        <div className="alaosa">
        <img
                src="/images/nettikasinot.webp"
                alt="Nettikasinot"
                title="Valitse korkealaatuinen nettikasino"
                class="lazyload"
                className="wrapperImage"
                width="300"
                height="286"
              />
              {documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <div className="alaosa2">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti2.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <div className="alaosa2">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti3.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      
      </Container>
    </Layout>
    )}
    />
  )
  }
}

export default Nettikasinot

